import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ICaptchaMapperProps,
  ICaptchaControllerProps,
  ICaptchaProps,
} from '../Captcha.types';

export default withCompController<
  ICaptchaMapperProps,
  ICaptchaControllerProps,
  ICaptchaProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    onTokenChange: token => {
      controllerUtils.updateProps({
        token,
      });
    },
  };
});
